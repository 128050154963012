<template>
  <div class="pageContol">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">证书管理</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">证书预警处理</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start;">
          <div class="searchbox">
            <div title="姓名" class="searchboxItem ci-full">
              <span class="itemLabel">姓名:</span>
              <el-input v-model="retrievalData.userName" type="text" clearable size="small" placeholder="请输入姓名" />
            </div>
            <div title="手机号" class="searchboxItem ci-full">
              <span class="itemLabel">手机号:</span>
              <el-input v-model="retrievalData.mobile" type="text" clearable size="small" placeholder="请输入手机号" />
            </div>
            <div title="培训工种" class="searchboxItem ci-full">
              <span class="itemLabel">培训工种:</span>
              <el-input v-model="retrievalData.occupationName" type="text" clearable size="small" placeholder="请输入培训工种" />
            </div>
            <div title="证书到期日期" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width: 8em;">证书到期日期:</span>
              <el-date-picker v-model="retrievalData.validityTime" type="daterange" clearable size="small"
                range-separator="至" start-placeholder="起始日期" end-placeholder="结束日期"
                value-format="yyyy-MM-dd"></el-date-picker>
            </div>
            <el-button type="primary" class="bgc-bv" round @click="getData()">查询</el-button>
            <el-button class="bgc-bv" style="margin: 0 10px" round @click="exportData">导出</el-button>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table ref="multipleTable" :data="tableData" :height="tableHeight" size="small" tooltip-effect="dark"
              style="width: 100%" :header-cell-style="tableHeader" stripe>
              <el-table-column label="序号" align="center" type="index" width="60" fixed :index="indexMethod" />
              <el-table-column label="姓名" align="left" show-overflow-tooltip prop="userName" width="100" />
              <el-table-column label="性别" align="left" show-overflow-tooltip prop="sex" width="80">
                <template slot-scope="scope">
                  {{ scope.row.sex == 1 ? '男' : '女' }}
                </template>
              </el-table-column>
              <el-table-column label="手机号" align="left" show-overflow-tooltip prop="mobile" width="150" />
              <el-table-column label="培训工种" align="left" show-overflow-tooltip prop="occupationName" min-width="150" />
              <el-table-column label="培训等级" align="left" show-overflow-tooltip prop="levelCode" width="100">
                <template slot-scope="scope">
                  {{ $setDictionary("ET_TRAIN_LEVEL", scope.row.levelCode) }}
                </template>
              </el-table-column>
              <el-table-column label="证书编号" align="left" show-overflow-tooltip prop="candidateNo" width="200" />
              <el-table-column label="到期日期" align="left" show-overflow-tooltip prop="validityDate" width="160" />
              <el-table-column label="发证机构" align="left" show-overflow-tooltip prop="authorityName" min-width="150" />
              <el-table-column label="预警时间" align="left" show-overflow-tooltip prop="sendTime" width="160" />
              <el-table-column label="预警内容" align="left" show-overflow-tooltip prop="sendContent" min-width="150" />
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { mapGetters } from "vuex";
export default {
  name: "certificateManage_certificateAlert",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      // 检索数据
      retrievalData: {
        userName: "", // 姓名
        mobile: "", // 手机号
        occupationName: "", // 培训工种
        validityTime: "", // 证书有效期
      },
    };
  },
  created() { },
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
      userJson: "getUser"
    }),
  },
  methods: {
    // 导出
    exportData() {
      let params = {
        ...this.retrievalData,
      };
      if (this.retrievalData.validityTime) {
        params.validityStartDate = this.retrievalData.validityTime[0];
        params.validityEndDate = this.retrievalData.validityTime[1];
      }
      this.$post("/biz/credential_making_system/ct-warning/export", params, 3000, true, 6).then((res) => {
        if (res.status == "0") {
          let list = res.data;
          for (let item of list) {
            console.log(item);
            if (!this.downloadItems.includes(item.taskId)) {
              this.$store.dispatch("pushDownloadItems", item.taskId);
            } else {
              this.$message.warning(
                "[" + item.fileName + "]已经申请下载,请耐心等待"
              );
            }
          }
        } else {
          this.$message.error(res.message);
        }
      })
    },
    // 获取 - 列表
    getData(pageNum = 1) {
      let params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        ...this.retrievalData,
      };
      if (this.retrievalData.validityTime) {
        params.validityStartDate = this.retrievalData.validityTime[0];
        params.validityEndDate = this.retrievalData.validityTime[1];
      }
      this.doFetch({
        url: "/biz/credential_making_system/ct-warning/pageList",
        params,
        pageNum,
      }, true, 6);
    },
  },
};
</script>
<style lang="less" scoped></style>